import React from 'react'
import styled from 'styled-components'
import {DescriptionText, DevicesMax, TitleText} from '../../appearance/styled'
import PopupContainer from '../Popup/PopupContainer'
import OurInvolvement from './OurInvolvement'

const ProjectPopup = ({t, language, projectPopupContent, setProjectPopupContent}) => {
    if (!projectPopupContent) {
        return null
    }

    const title = t('projects.' + projectPopupContent?.name + '.name')

    return (
        <PopupContainer
            show={projectPopupContent}
            onClose={() => setProjectPopupContent(null)}
            noPadding>
            <Container>
                <ProjectName lang={language}>{title}</ProjectName>
                {projectPopupContent.videoSrc && (
                    <VideoContainer>
                        <ProjectVideo
                            frameBorder="0"
                            allowFullScreen
                            src={`${projectPopupContent.videoSrc}`}
                        />
                    </VideoContainer>
                )}
                <TextSection>
                    <SectionTitle lang={language}>
                        {t('projects.about_title', {projectName: title})}
                    </SectionTitle>
                    <About>{t('projects.' + projectPopupContent?.name + '.about')}</About>
                </TextSection>
                <TextSection lang={language}>
                    <SectionTitle>{t('projects.our_involvement_title')}</SectionTitle>
                    <OurInvolvement t={t} projectPopupContent={projectPopupContent} />
                </TextSection>

                <ImagesContainer>
                    {projectPopupContent.images?.map((img, i) => (
                        <ImageContainer
                            key={img}
                            target="_blank"
                            rel="noreferrer"
                            href={projectPopupContent.link}>
                            <Image src={img} />
                        </ImageContainer>
                    ))}
                </ImagesContainer>
            </Container>
        </PopupContainer>
    )
}

export default ProjectPopup

const Container = styled.section`
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
    padding-top: 40px;
`

const ProjectName = styled(TitleText)`
    text-transform: capitalize;
    text-align: center;
`
const VideoContainer = styled.div`
    display: block;
    margin: 0 auto 30px;
    height: 400px;
    width: 100%;

    @media ${DevicesMax.tablet} {
        height: 200px;
    }
`
const ProjectVideo = styled.iframe`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 400px;
    width: 100%;
    @media ${DevicesMax.tablet} {
        height: 200px;
    }
`
const About = styled(DescriptionText)`
    @media ${DevicesMax.tablet} {
        font-size: 15px;
        line-height: 25px;
    }
`
const ImagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 30px;
    justify-content: center;
    height: min-content;
`
const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
const TextSection = styled.section`
    width: 100%;
    margin-bottom: 30px;
    padding-inline: 140px;
    @media ${DevicesMax.tablet} {
        padding-inline: 20px;
    }
`
const SectionTitle = styled(TitleText)`
    font-size: 30px;
    line-height: 30px;
    display: block;
    margin-bottom: 10px;

    @media ${DevicesMax.tablet} {
        font-size: 25px;
    }
`

const ImageContainer = styled.a`
    height: 600px;
    width: 100%;
    @media ${DevicesMax.tablet} {
        height: 225px;
    }
`
