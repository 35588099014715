import React from 'react'
import styled from 'styled-components'
import HomeMobileContent from './Home/HomeMobileContent'
import HomeDesktopContent from '../Sections/Home/HomeDesktopContent'

const Home = () => {
    return (
        <Container id="home">
            <HomeDesktopContent />
            <HomeMobileContent />
        </Container>
    )
}

export default Home

const Container = styled.section``
