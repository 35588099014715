import React from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {PAGES_DEFAULT_COLORS} from '../../constants'
import {useSelectedColor} from '../../uses/context.uses'
import {getFormatedColorName} from '../../helpers/utils'
import ContactForm from '../ContactForm/ContactForm'
import {DevicesMax, TitleText} from '../../appearance/styled'

const DEFAULT_COLOR = PAGES_DEFAULT_COLORS.contact
const Contact = () => {
    const {t, language} = useI18next()
    const [color] = useSelectedColor()
    const {register} = useForm()

    const titleSrc = `/images/sidenav/${getFormatedColorName(color, DEFAULT_COLOR)}/contact.png`
    const src = `/images/contact/${getFormatedColorName(color, DEFAULT_COLOR)}/contact.png`
    return (
        <Container id="contact">
            <Image src={src} />
            <Content>
                <Title src={titleSrc} lang={language}>
                    {t('contact.title')}
                </Title>
                <ContactForm t={t} language={language} register={register} />
            </Content>
        </Container>
    )
}

export default Contact

const Container = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    text-align: center;
`

const Content = styled.div`
    width: 100%;
`

const Title = styled(TitleText)`
    font-size: 100px;
    line-height: 112px;

    @media ${DevicesMax.mobile} {
        font-size: 32px;
        line-height: 35px;
        background: url(${({src}) => src});
        background-size: 100% 80%;
        background-repeat: no-repeat;
        background-position: right bottom;
    }
`

const Image = styled.img`
    position: absolute;
    top: -40px;
    right: 40px;
    height: 600px;
    width: 600px;
    z-index: 0;

    @media ${DevicesMax.mobile} {
        display: none;
    }
`
