import {Trans, useI18next} from 'gatsby-plugin-react-i18next'
import React, {useState} from 'react'
import styled from 'styled-components'
import {
    DescriptionText,
    DevicesMax,
    getFontFamilyByLang,
    RolesImage,
    RolesImageContainer,
    TitleText,
} from '../../../appearance/styled'
import {ROLES} from '../../../constants'
import {getDirection} from '../../../helpers/utils'
import {motion} from 'framer-motion'
import {getMobileTitleSrc} from '../../../helpers/utils'
import WhatWeDoPopup from './WhatWeDoPopup'
import MarkedText from '../../Display/MarkedText'

const getComponentDirection = (direction, i) => {
    if (i % 2 === 0) {
        return direction === 'rtl' ? 'rtl' : 'ltr'
    }
    return direction === 'rtl' ? 'ltr' : 'rtl'
}

const WhateDoMobile = () => {
    const {t, language} = useI18next()
    const direction = getDirection(language)
    const titleImageSrc = getMobileTitleSrc('projects')
    const [showPopup, setShowPopup] = useState(null)

    return (
        <MobileContainer lang={language}>
            <Title lang={language} src={titleImageSrc}>
                {t('side_nav.what_we_do')}
            </Title>

            <Description lang={language}>
                <Trans
                    i18nKey={'home.mobile_description'}
                    components={{
                        mark: <MarkedText />,
                    }}>
                    {'home.mobile_description'}
                </Trans>
            </Description>
            <SectionsContainer>
                {ROLES.map(({name, src}, i) => {
                    const componentDirection = getComponentDirection(direction, i)
                    const description = t('what_we_do.' + name + '.description')
                    const title = t('what_we_do.' + name + '.title')

                    return (
                        <Section
                            offscreen
                            initial={{opacity: 0, scale: 0.5}}
                            whileInView={{opacity: 1, scale: 1}}
                            viewport={{once: true}}
                            transition={{duration: 1}}
                            key={name + '_' + i}
                            id={name}
                            onClick={() => setShowPopup(name)}
                            dir={componentDirection}>
                            <RolesImageContainer>
                                <RolesImage src={src} />
                                {t('what_we_do.' + name + '.title')}
                            </RolesImageContainer>
                            <WhatWeDoPopup
                                src={src}
                                name={name}
                                title={title}
                                language={language}
                                showPopup={showPopup}
                                description={description}
                                setShowPopup={setShowPopup}
                            />
                        </Section>
                    )
                })}
            </SectionsContainer>
        </MobileContainer>
    )
}

export default WhateDoMobile

const MobileContainer = styled.div`
    display: none;
    ${({lang}) => getFontFamilyByLang(lang)};

    @media ${DevicesMax.mobile} {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`
const Description = styled(DescriptionText)`
    margin-bottom: 50px;
    &:lang(he) {
        font-family: Matador En;
        font-size: 20px;
    }
`

const SectionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
`
const Section = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: ${({start}) => (start ? 'flex-start' : 'flex-end')};
    justify-content: center;
    height: 180px;
    font-size: 17px;
    width: 45%;
`

const Title = styled(TitleText)`
    width: max-content;
    position: relative;
    background: url(${({src}) => src});
    background-repeat: no-repeat;
    margin-bottom: 80px;

    @media ${DevicesMax.tablet} {
        font-size: 50px;
        &:lang(en) {
            background-size: 80% 100%;
        }
        padding-bottom: 0px;
        margin-bottom: 30px;
        width: 100%;
    }
`
