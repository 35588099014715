import React from 'react'
import styled from 'styled-components'
import {DevicesMax} from '../../appearance/styled'
import WhatWeDoDesktop from './WhatWeDo/WhatWeDoDesktop'
import WhateDoMobile from './WhatWeDo/WhateDoMobile'

const WhatWeDo = () => {
    return (
        <Container id="what_we_do">
            <WhatWeDoDesktop />
            <WhateDoMobile />
        </Container>
    )
}

export default WhatWeDo

const Container = styled.section`
    display: flex;
    /* flex-direction: column; */
    height: 25vh;
    width: 100%;
    padding: 10px;
    margin-top: 40px;
    margin-block: 80px;

    @media ${DevicesMax.mobile} {
        height: auto;
        margin-block: 50px;
    }
`
