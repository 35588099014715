import React from 'react'
import {useI18next} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import {DevicesMax, TitleText} from '../../appearance/styled'
import {Colors} from '../../appearance'
import {motion} from 'framer-motion'

const ProjectContainer = ({project, id, onOpenProjectDetails}) => {
    const {t, language} = useI18next()

    const title = project?.name && t('projects.' + project?.name + '.name')
    const noProject = !project?.name
    return (
        <Container id={'projects_' + id} onClick={onOpenProjectDetails} noProject={noProject}>
            <motion.div
                style={{height: '100%', width: '100%'}}
                transition={{duration: 0.6, ease: 'easeIn'}}
                initial={{y: '50%', opacity: 0}}
                whileInView={{y: 0, opacity: 1}}
                viewport={{once: true}}>
                <ImageContainer noProject={noProject}>
                    <BlackCover hideCover={!project.name}>
                        <ProjectName lang={language}>{title}</ProjectName>
                    </BlackCover>
                    <Image src={project.images[0]} />
                </ImageContainer>
            </motion.div>
        </Container>
    )
}

export default ProjectContainer

const BlackCover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    ${({hideCover}) => !hideCover && 'background: #0000008c'};
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Container = styled.div`
    height: 400px;
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    ${({noProject}) => !noProject && 'cursor: pointer'};
    & ${BlackCover} {
        & :hover {
            background: transparent;
        }
    }
    & :hover {
        img {
            ${({noProject}) => !noProject && 'transform: scale3d(1.2, 1.2, 1)'};
        }
    }

    @media ${DevicesMax.tablet} {
        width: 100%;
    }
`

const ProjectName = styled(TitleText)`
    color: ${Colors.WHITE};
`

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s;
`
