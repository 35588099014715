import {graphql} from 'gatsby'
import React from 'react'
import Page from '../components/Layout/Page'
import Home from '../components/Sections/Home'
import WhatWeDo from '../components/Sections/WhatWeDo'
import Projects from '../components/Sections/Projects'
import Contact from '../components/Sections/Contact'
import Partners from '../components/Sections/Partners'

const Index = ({...p}) => {
    return (
        <Page {...p}>
            <Home />
            <WhatWeDo />
            <Projects />
            <Partners />
            <Contact />
        </Page>
    )
}

export default Index

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
