import styled from 'styled-components'
import React from 'react'
import {DescriptionText, DevicesMax} from '../../../appearance/styled'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {useScrollPosition} from '../../../uses/layout.uses'
import LetsTalkButton from '../../LetsTalkButton'

const HomeMobileContent = () => {
    const {t} = useTranslation()
    const {language} = useI18next()
    const scrollPosition = useScrollPosition()
    const right = scrollPosition < 120 ? 116 : Math.min(scrollPosition, 250)
    const rotate = scrollPosition < 120 ? 0 : Math.max(scrollPosition * -0.1, -40)

    return (
        <MobileContainer>
            <Logo src={'/images/logo_with_text.png'} />
            <Slogan>{t('home.slogan')}</Slogan>
            <Text lang={language}>{t('home.mobile_home_description')}</Text>
            <LetsTalkButton />

            <MarkerContainer>
                <Cap right={right} src={'/images/marker_cap.png'} />
                <Marker rotate={rotate} src={'/images/PINK_MARKER.png'} />
            </MarkerContainer>
        </MobileContainer>
    )
}

export default HomeMobileContent

const MobileContainer = styled.div`
    display: none;
    @media ${DevicesMax.mobile} {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`

const Description = styled(DescriptionText)`
    font-weight: normal;
    &:lang(he) {
        font-family: Matador En;
    }
`

const Logo = styled.img`
    max-width: min(95vw, 370px);
    margin-bottom: 20px;
`

const Slogan = styled(Description)`
    font-size: 30px;
    line-height: 50px;
    margin-bottom: 0;
    text-transform: capitalize;
`
const Text = styled(Description)`
    margin-bottom: 50px;
    letter-spacing: 0.7px;
    font-size: 17px;
`

const MarkerContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-bottom: 80px;
    position: relative;
`
const Cap = styled.img`
    height: 40px;
    margin-top: 8px;
    position: absolute;
    margin-right: ${({right}) => right}px;
    z-index: 1;
`
const Marker = styled.img`
    height: 50px;
    transform: ${({rotate}) => `rotateZ(${rotate || 0}deg)`};
`
