import React from 'react'
import {useContext} from 'react'
import styled from 'styled-components'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {getFormatedColorName, getMobileTitleSrc} from '../../../helpers/utils'
import {useIsMobile} from '../../../uses/layout.uses'
import {DescriptionText, DevicesMax, StyledLink, TitleText} from '../../../appearance/styled'
import {PAGES_DEFAULT_COLORS} from '../../../constants'
import {GlobalProvider} from '../../Context'

const getImageTitleSrc = (isMobile, selectedColor) => {
    const titleSrc = `/images/home/${getFormatedColorName(selectedColor, DEFAULT_COLOR)}/title.png`
    const mobileSrc = getMobileTitleSrc('home')
    return isMobile ? mobileSrc : titleSrc
}

const DEFAULT_COLOR = PAGES_DEFAULT_COLORS.home
const HomeDesktopContent = () => {
    const {t} = useTranslation()
    const {language} = useI18next()
    const isMobile = useIsMobile()
    const {selectedColor} = useContext(GlobalProvider)

    const titleImageSrc = getImageTitleSrc(isMobile, selectedColor)

    const readMoreSrc = `/images/home/${getFormatedColorName(
        selectedColor,
        DEFAULT_COLOR
    )}/read_more.png`

    return (
        <DesktopContainer>
            <Title lang={language} src={titleImageSrc}>
                {t('home.title')}
            </Title>
            <Description lang={language}>{t('home.description')}</Description>
            <ReadMoreButton>
                <ReadMoreLink to={'#projects'}>{t('home.read_more')}</ReadMoreLink>
                <ReadMoreImage src={readMoreSrc} />
            </ReadMoreButton>
        </DesktopContainer>
    )
}

export default HomeDesktopContent

const DesktopContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media ${DevicesMax.mobile} {
        display: none;
    }
`
const Title = styled(TitleText)`
    width: max-content;
    position: relative;
    background: url(${({src}) => src});
    background-size: 83% auto;
    background-repeat: no-repeat;
    background-position: right bottom;
    margin-bottom: 20px;
    @media ${DevicesMax.tablet} {
        background-position: 5px bottom;
        background-size: 91% auto;
        padding-bottom: 13px;
        width: 100%;
    }
`
const Description = styled(DescriptionText)`
    &:lang(he) {
        font-family: Matador En;
        font-size: 18px;
    }
`

const ReadMoreButton = styled.button`
    position: relative;
    max-width: 243px;
    @media ${DevicesMax.mobile} {
        max-width: 184px;
    }
`
const ReadMoreImage = styled.img`
    width: 100%;
    height: 30px;
    @media ${DevicesMax.mobile} {
        height: 20px;
    }
`
const ReadMoreLink = styled(StyledLink)`
    font-family: Simple Pro;
    position: absolute;
    font-size: 17px;

    @media ${DevicesMax.mobile} {
        font-size: 12px;
    }
`
