import React from 'react'
import styled from 'styled-components'
import {DescriptionText, DevicesMax} from '../../appearance/styled'

const OurInvolvement = ({t, projectPopupContent}) => {
    const items = projectPopupContent.our_involvement

    return (
        <Container>
            {items?.map((item, i) => (
                <Item
                    key={'OurInvolvement_' + i}
                    color={projectPopupContent.color}
                    bgColor={projectPopupContent.bgColor}>
                    <ItemDescription>{item}</ItemDescription>
                </Item>
            ))}
        </Container>
    )
}

export default OurInvolvement

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
`

const Item = styled.div`
    border-radius: 40px;
    box-shadow: 0 0 4px 1px #0000002b;
    min-width: 100px;
    background: ${({bgColor}) => bgColor || 'transparent'};
    color: ${({color}) => color || 'inherit'};
    padding-inline: 20px;
    text-align: center;
`

const ItemDescription = styled(DescriptionText)`
    @media ${DevicesMax.tablet} {
        font-size: 12px;
        line-height: 25px;
    }
`
