import React from 'react'
import styled from 'styled-components'

import {Colors} from '../../appearance'
import {motion} from 'framer-motion'

const MarkedText = ({markerColor, ...p}) => {
    return (
        <motion.div
            offscreen
            style={{display: 'inline'}}
            initial={{backgroundPosition: '0%'}}
            whileInView={{backgroundPosition: '-100%'}}
            viewport={{once: true}}
            transition={{duration: 1}}>
            <Mark markerColor={markerColor} {...p} />
        </motion.div>
    )
}

export default MarkedText

const Mark = styled.mark`
    background-color: none;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        ${({markerColor}) => markerColor || Colors.PINK} 100%
    );
    padding-inline: 5px;
    border-radius: 2px;
    background-size: 200% 100%;
    background-position: inherit;
`
