import React from 'react'
import styled from 'styled-components'
import {
    BOX_SHADOW,
    PopupCloseButton,
    RolesImage,
    SectionDescription,
    SectionTitle,
} from '../../../appearance/styled'
import {motion} from 'framer-motion'
import {Colors} from '../../../appearance'

const colors = {
    development: Colors.BLUE,
    codebulance: Colors.PINK,
    product: Colors.GREEN,
    qa: Colors.BLUE,
    research: Colors.PINK,
    strategy: Colors.GREEN,
    ai: Colors.BLUE,
}

const WhatWeDoPopup = ({showPopup, setShowPopup, language, name, src, title, description}) => {
    return (
        showPopup &&
        showPopup === name && (
            <Popup
                bgcolor={colors[name]}
                initial={{scale: 0}}
                whileInView={{scale: 1}}
                transition={{duration: 0.2}}>
                <RolesImage popup height={20} src={src} />
                <SectionTitle lang={language}>{title}</SectionTitle>
                <Description lang={language}>{description}</Description>
                <PopupCloseButton
                    onClick={(ev) => {
                        ev.stopPropagation()
                        setShowPopup(null)
                    }}>
                    ✕
                </PopupCloseButton>
            </Popup>
        )
    )
}

export default WhatWeDoPopup

const Popup = styled(motion.div)`
    position: absolute;
    top: 0;
    background: ${({bgcolor}) => bgcolor || Colors.GREEN};
    position: absolute;
    border-radius: 4px;
    z-index: 5;
    width: 80vw;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 10px;
    ${BOX_SHADOW};
    left: 0;
    right: 0;
`

const Description = styled(SectionDescription)`
    font-size: 15px;
    line-height: 30px;
`
