import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import {CUSTOMERS} from '../../constants'
import {useIsMobile} from '../../uses/layout.uses'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {DevicesMax, SectionTitle} from '../../appearance/styled'

const Partners = () => {
    const isMobile = useIsMobile()
    const {t, language} = useI18next()

    return (
        <Container id="partners">
            <TrustedBy lang={language}>{t('partners.trusted_by')}</TrustedBy>
            <InnerContainer>
                {CUSTOMERS.map((customer) => (
                    <Customer
                        key={'partners_' + customer.name}
                        target="_blank"
                        href={customer.url}
                        w={isMobile ? 50 : 30}>
                        <CustomerImage src={customer.logo} />
                    </Customer>
                ))}
            </InnerContainer>
        </Container>
    )
}

export default Partners

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 180px;

    @media ${DevicesMax.mobile} {
        margin-top: 20px;
        margin-bottom: 100px;
    }
`

const InnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    list-style: none;
    padding-inline-start: 0;
`
const PosedCustomer = posed.div({
    // hoverable: true,
    init: {scale: 0.9},
    // hover: {scale: 1},
})

const Customer = styled(PosedCustomer)`
    width: 150px;
    /* @media ${DevicesMax.mobile} {
    } */
`

const CustomerImage = styled.img`
    padding: 20px 10px;
    width: 100%;
    filter: grayscale(1);
`

const TrustedBy = styled(SectionTitle)`
    margin-bottom: 20px;
`
