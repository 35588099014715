import React, {useState} from 'react'
import styled from 'styled-components'
import {PROJECTS} from '../../constants'
import ProjectContainer from '../Projects/ProjectContainer'
import {getMobileTitleSrc} from '../../helpers/utils'
import {DevicesMax, TitleText} from '../../appearance/styled'
import {useI18next} from 'gatsby-plugin-react-i18next'
import ProjectPopup from '../Projects/ProjectPopup'
import {useIsMobile} from '../../uses/layout.uses'

const Projects = () => {
    const {t, language} = useI18next()
    const isMobile = useIsMobile()
    const titleImageSrc = getMobileTitleSrc('projects')
    const [projectPopupContent, setProjectPopupContent] = useState(null)

    return (
        <Container id="projects">
            <Title lang={language} src={titleImageSrc}>
                {t('side_nav.projects')}
            </Title>
            <ProjectsContainer>
                {Object.keys(PROJECTS).map((project, i) => (
                    <ProjectContainer
                        key={'projects_' + i}
                        id={project}
                        project={PROJECTS[project]}
                        onOpenProjectDetails={() => setProjectPopupContent(PROJECTS[project])}
                    />
                ))}
                <ProjectContainer
                    project={{
                        name: '',
                        images: [
                            `/images/${isMobile ? 'next_project_mobile' : 'next_project'}.png`,
                        ],
                    }}
                    onOpenProjectDetails={() => {}}
                />
            </ProjectsContainer>
            <ProjectPopup
                t={t}
                language={language}
                projectPopupContent={projectPopupContent}
                setProjectPopupContent={setProjectPopupContent}
            />
        </Container>
    )
}

export default Projects

const Container = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
`

const Title = styled(TitleText)`
    position: relative;
    padding: 22px 0 15px 0;
    background: url(${({src}) => src});
    background-repeat: no-repeat;
    margin-bottom: 30px;
    text-transform: capitalize;
    background-size: 100% 45%;
    background-position: bottom;
    width: max-content;

    @media ${DevicesMax.tablet} {
        font-size: 50px;
    }
`

const ProjectsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 20px;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
`
