import React from 'react'
import {useI18next} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import {
    DevicesMax,
    getFontFamilyByLang,
    RolesImage,
    RolesImageContainer,
    ScrollAnimation,
} from '../../../appearance/styled'
import {ROLES} from '../../../constants'

const WhatWeDoDesktop = () => {
    const {t, language} = useI18next()
    const items = [...ROLES, ...ROLES, ...ROLES, ...ROLES]

    return (
        <RolesContainer lang={language}>
            {items.map((role, i) => (
                <RolesImageContainer key={i}>
                    <RolesImage src={role.src} />
                    {t('what_we_do.' + role.name + '.title')}
                </RolesImageContainer>
            ))}
        </RolesContainer>
    )
}

export default WhatWeDoDesktop

const RolesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    ${ScrollAnimation};
    width: calc(250px * 3);
    ${({lang}) => getFontFamilyByLang(lang)};
    &:hover {
        animation-play-state: paused;
    }
    @media ${DevicesMax.mobile} {
        display: none;
    }
`
